html, body, #root {
  height: 100%;
}


/* header logos fixes layout */
header {
  border-bottom: solid 1px #bbbbbb;
  background: linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(255,255,255,1) 100%);
}

/* #header {
  height: 100px;
} */

header img {
  padding: 10px 20px;
}

#poweringhealth {
  height: 80px;
}

#usaid {
  height: 75px;
}

#esmap, #wbg {
  height: 65px;
}

#homer {
  height: 70px;
}

#wecaresolar {
  height: 80px;
}

.imgsize {height: 50px}

/* footer */
footer {
  border-top: solid 1px #bbbbbb;
  background: #fafafa;
}
