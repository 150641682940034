#root {background-color: #EDF0F5;height:auto}
.WB-div {font-family: 'Roboto', sans-serif}
#root .WB-header {border-bottom: none;background: #fff}
.WB-header .WB-header-container {height: 72px;padding: 0 15rem}
.WB-Homer-pro-logo {float:right}
@media (min-width: 1200px) {
    #root .container {max-width: 1424px}
}
.container hr, .wP-dp-none {display: none}
.WB-div fieldset, .WP-intro {-webkit-box-shadow: 0px 2px 4px rgb(31 30 47 / 10%);
    box-shadow: 0px 2px 4px rgb(31 30 47 / 10%);
    margin-bottom: 4px;
    background-color: #fff;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,0.125);
    border-radius: 0.25rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0.25rem;
}

.WB-location {
    position: absolute;
    top: 2.6rem;
    left: 45rem;
    width: 92%;
    z-index: 999;
}
.WB-location-control {
    border-radius: 20px;
    height: 35px;
    border-color: #D3D7DA;
}
.WB-location-button {
    border: none;
    background-color: #E9ECEF;
    position: absolute;
    right: 0rem;
    border-radius: 50px;
}
.WB-div label, .form-control-plaintext {color: #303E67;font-size: 0.875rem}
.WB-div legend { 
    font-size: 16px;
    color: #303E67;
    font-weight: 500
}
.form-control-plaintext:focus-visible {border: 1px solid #E8EBF3 }
.WB-mg-t-3rem {margin-top: 3rem}
.WB-div .form-control {border: 1px solid #E8EBF3; height: 37px;font-size: 0.875rem}
.WB-div .input-group-text { background-color: #E8EBF3;border: 0;color: #495057;font-size: 0.875rem;}
.WB-div .form-group {margin-bottom: 0.4rem}
.WB-div .WB-location-button {font-size: 0.875rem}
.WB-div .WP-map {margin-top: 0 !important; margin-bottom: 0!important}
.WB-div h5 {font-size: 0.875rem; color: #303E67}
.wp-power-ass .form-group .col-3 {    
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.WB-div .wp-power-ass {padding-top: 1rem;padding-left: 2rem}
.WB-div .WB-Power-heading, .WB-div .WP-electricLoadLegend-heading, .WB-homer-run {
    position: relative;
    top: 1.2rem;
    margin-left: 1rem;
}
.WB-div .wp-power-ass {
    padding-top: 1.5rem;
    padding-left: 3rem;
}
.wp-pd-t-3rem {padding-top: 2rem}
.WB-table-label-pd {padding: 0 7px}

/* Table CSS Start */
.WB-table {color: #303E67;padding: 10px;display: inherit;width: 100%;margin-top:5px}
.WB-table thead tr th {background-color: #F1F5FA;border: 1px solid #EAF0F7;font-size: 14px;vertical-align: top}
.WB-table tr th, .WB-Fw-500 {font-weight: 500}
.WB-table .WB-top-table-secondary {background-color: #fff;border: 1px solid #EAF0F7;font-size: 14px; color:#303E67;border-top: 1px solid #EAF0F7;border-bottom: 1px solid transparent}
.WB-table .WB-top-table-secondary td, .table-secondary > td {border-color: transparent;background-color: transparent}
.WB-sub-table-secondary {cursor: pointer;
    border: 1px solid #EAF0F7;
    border-bottom: 1px solid transparent;
    background-color: #ffff;
    font-size: 14px;}
.WB-sub-s-data, .WB-add-button {border-left: 1px solid #EAF0F7;font-size: 14px;border-right: 1px solid #EAF0F7}
.WB-sub-s-data .WB-field-first {padding-left: 55px} 
.WB-add-button td .btn-outline-primary, .btn-outline-primary {
    background: linear-gradient(14deg, #1761fd 0%, rgba(23,97,253,0.6));
    color: #fff;
    -webkit-box-shadow: 0 7px 14px 0 rgb(23 97 253 / 50%);
    box-shadow: 0 2px 14px 0 rgb(23 97 253 / 50%);
    border: none;
    margin-left: 49px;
} 
.WP-total {background-color: #f1f5fa;border: 1px solid #EAF0F7} 
.WB-div .WB-homer-run {margin-left:0} 
.WB-div .wp-calculate-btn {
    display: flex;
    margin: 0 auto;
    margin-top: 10px;
    padding: 6px 72px;
    margin-bottom: 10px;
}
.WB-div .switch {border-radius:50px;min-height: calc(1.2em + 0.5rem + 2px);background-color: #CED4DD}
.WB-div .switch-handle {border-radius: 50px}
.WB-div .WB-sub-s-data:hover, .WB-add-button:hover, .table-hover tbody tr:hover {background-color: #EAF0F7}
.WB-div .btn-light {background-color: #CED4DD}
.WB-div .WB-top-table-secondary:hover {background-color: #e7e7e7}
.WB-div .WB-error-msg {text-align: center;padding-top: 10px}
.WB-field-first .btn-outline-danger {border-radius: 20px;padding: 0.1rem 0.6rem;font-size: unset}
.WB-el-button {text-align: right}
.WB-el-button .btn-outline-primary, .WB-el-button .btn-outline-secondary {    
    font-size: 14px;
    padding: 0.2rem 0.5rem;
    margin-left: 5px;
    margin-right: 0.5rem !important;
}
.WP-mg-top--13 {margin-top:-13px}
.WB-pd-t-10px {padding-top: 10px}
/* Intro CSS Start */

.WP-intro {margin-top: 10px;padding: 1rem;color: #303E67}
.WP-intro h2 {font-size: 1.2rem;color: #303E67}
.WP-intro h5 {font-size: 1rem}
.WP-intro p, .moreInfoText strong {font-size: 14px;padding: 0 18px}
.WP-intro .moreInfoText p {margin-bottom: .3rem}
.WP-intro .moreInfoText strong{margin-top: 1rem;display: block;}
.WP-intro ol li {margin-top:.7rem}
.col-form-label-css {color: #303E67;font-size: 0.875rem;}
.UL-tier-wd10p {width:10%}
.Ul-d-flex {display: flex}
.UL-pd-1015 {padding: 10px 15px}
.UL-wd-50p {width: 50%}
.UL-mg-t-5px {margin-top: 5px}
.UL-R-table .UL-R-header-bg {background: #F1F5FA !important;font-size: 0.840rem;}

@media only screen and (min-width:321px) and (max-width:991px) {
    .WB-location {top: 31.8rem;left: auto;}
}
@media only screen and (min-width: 992px) {
    .WB-location {left: 30rem}
}
@media only screen and (min-width: 1200px) {
    .WB-location {left: 38rem}
} 
@media (min-width:1281px) {
    .WB-location {left: 46rem}
}

/* Households css */
.UL-R-row:nth-of-type(odd) {
    background: #fff;
}
.UL-R-row {
    display: table-row;
    background: #f9f9f9;
}
.UL-R-cell {
    padding: 6px 12px;
    display: table-cell;
}
@media screen and (max-width: 580px){
    .UL-R-table {
        display: block;
    }
    .UL-R-row.UL-R-header {
        padding: 0;
        height: 6px;
    }
    .UL-R-row.UL-R-header .UL-R-cell {
        display: none;
    }
    .UL-R-row .UL-R-cell {
        margin-bottom: 10px;
    }
    .UL-R-cell {
        padding: 2px 16px;
        display: block;
    }
    .UL-R-row {
        padding: 14px 0 7px;
        display: block;
    }
    .UL-R-row .UL-R-cell:before {
        margin-bottom: 3px;
        content: attr(data-title);
        min-width: 98px;
        font-size: 10px;
        line-height: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: #969696;
        display: block;
    }
}